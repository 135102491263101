"use strict";

document.addEventListener('DOMContentLoaded', function () {
  $(document).foundation();
  var nav = document.getElementById('site-navigation');
  var menuBtn = document.querySelector('.menu-btn');
  var lines = document.querySelectorAll('.menu-btn .line');
  var ul = document.querySelectorAll("nav#site-navigation ul li ul");
  var li = document.querySelectorAll("nav#site-navigation ul li ul li");
  var fadeElements = document.querySelectorAll('.fade-in');
  var header = document.querySelector('header');
  var navbarHeight = header.offsetHeight;
  var didScroll = false;
  var lastScrollTop = 0;
  var delta = 5;
  menuBtn.addEventListener('click', function () {
    nav.classList.toggle('open-nav');
    lines.forEach(function (line) {
      return line.classList.toggle('line-cross');
    });
    lines[1].classList.toggle('line-fade-out');
  });
  function isScrolledIntoView(elem) {
    var docViewTop = window.scrollY;
    var docViewBottom = docViewTop + window.innerHeight + 200;
    var elemTop = elem.offsetTop;
    var elemBottom = elemTop + elem.offsetHeight;
    return elemBottom <= docViewBottom && elemTop >= docViewTop;
  }
  ul.forEach(function (ul) {
    if (ul.classList.contains('children')) {
      ul.parentNode.classList.add('hasChildren');
    }
  });
  li.forEach(function (li) {
    if (li.classList.contains('hasChildren')) {
      li.querySelector('a').insertAdjacentHTML('beforeend', "<a href='javascript:void(0)' class='expandChild' title='Child item'></a>");
    }
  });
  document.addEventListener('click', function (e) {
    if (!nav.contains(e.target)) {
      nav.classList.remove('nav-open');
    }
  });
  window.addEventListener('scroll', function () {
    fadeElements.forEach(function (element) {
      if (isScrolledIntoView(element)) {
        element.classList.add('animation-started');
      }
    });
    didScroll = true;
  });
  var touchStartEvent = new Event('touchstart', {
    bubbles: true
  });
  var touchMoveEvent = new Event('touchmove', {
    bubbles: true
  });
  document.addEventListener('touchstart', function (e) {
    if (!e.target.classList.contains('noPreventDefault')) {
      e.preventDefault();
    }
  }, {
    passive: false
  });
  document.addEventListener('touchmove', function (e) {
    if (!e.target.classList.contains('noPreventDefault')) {
      e.preventDefault();
    }
  }, {
    passive: false
  });
});
window.addEventListener("scroll", function () {
  var distance = window.scrollY;
  document.querySelector(".parallax-window").style.transform = "translateY(".concat(distance * 0.3, "px)");
});